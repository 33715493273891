<template>
  <!-- 巡检标签 巡检点 -->
  <div class="setting_main_bg">
    <div class="lable_cont">
      <div class="lable_box">
        <div class="btn_group" v-if="roleBtnArray[0].power">
          <div class="iconfont iconjia settingAdd" @click="handleAdd"><span>新增</span></div>
        </div>
        <div class="flexAC" :class="roleBtnArray[0].power === true?'ut_top':''">
          <el-input v-model="inpointname"  placeholder="请输入搜索内容" class="contInput"></el-input>
          <el-button class="settingSearch" @click="handleSearch">搜索</el-button>
          <el-button class="settingReset" @click="handleReset">重置</el-button>
        </div>
        <div class="lable_table" style="position: relative;">
          <p class="tableTip" v-show="tipShow"><span class="triangle"></span><span class="tipT">例：4+2（关联设备+自定义设备）</span></p>
          <el-table
            stripe
            :data="lableList"
            style="width: 100%">
            <el-table-column
              label="序号"
              type="index">
            </el-table-column>
            <el-table-column
              label="单位名称"
              prop="companyname"
              show-overflow-tooltip>
            </el-table-column>
            <el-table-column
              label="巡检点名称"
              prop="inpointname"
              show-overflow-tooltip>
            </el-table-column>
            <el-table-column
              label="巡检点编号"
              prop="inpointcode"
              show-overflow-tooltip>
            </el-table-column>
            <el-table-column
              label="类型"
              width="100px;"
              prop="inpointtype">
              <template slot-scope="scope">
                <span v-if="scope.row.inpointtype === '1'" style="color: #4295FF;cursor: pointer;"  @click="showCode(scope.row)">二维码</span>
                <span v-if="scope.row.inpointtype === '2'" >NFC</span>
              </template>
            </el-table-column>
            <el-table-column
              label="设备数量"
              width="100px;">
              <template slot="header" slot-scope="scope">
                <span>设备数量</span>
                <i class="el-icon-question tableHI" @click="showHideTip"></i>
              </template>
              <template slot-scope="scope"><span style="color: #4295FF;">{{ scope.row.equcount }}</span></template>
            </el-table-column>
            <el-table-column
              label="创建时间"
              prop="inpointtime">
            </el-table-column>
            <el-table-column
              label="巡检要求"
              prop="inpointmark"
              show-overflow-tooltip>
            </el-table-column>
            <el-table-column
              label="操作">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="handleDetail(scope.row)">详情</el-button>
                <el-button v-if="roleBtnArray[2].power" type="text" size="small" @click="handleEdit(scope.row)">编辑</el-button>
                <el-button v-if="roleBtnArray[1].power" type="text" size="small" style="color:red;" @click="handleDelete(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination
          @current-change="PageChange"
          :current-page="page"
          :page-sizes="[10]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          class="settingPage">
        </el-pagination>
      </div>
    </div>

    <!-- 新增 -->
    <el-dialog title="巡检点新增" :visible.sync="addFromShow" width="600px" center class="gyDialog" :close-on-click-modal="false" :before-close="addFromCancel">
      <el-form :model="addfrom" :rules="formRules" ref="addFrom">
        <el-form-item label="巡检点类型"  prop="inpointtype">
          <div class="typeBox">
            <el-radio-group v-model="addfrom.inpointtype">
              <el-radio label="1">二维码</el-radio>
              <el-radio label="2" disabled>NFC</el-radio>
            </el-radio-group>
          </div>
        </el-form-item>
        <el-form-item label="巡检点名称"  prop="inpointname">
          <el-input v-model="addfrom.inpointname" placeholder="请输入巡检点名称"></el-input>
        </el-form-item>
        <el-form-item label="定位" prop="inpointadress">
          <el-input v-model="addfrom.inpointadress" placeholder="请选择巡检点位置">
            <el-button slot="append" icon="el-icon-location" @click="showAddress"></el-button>
          </el-input>
        </el-form-item>
        <el-form-item label="关联设备"  prop="inequlistid">
          <el-select v-model="addfrom.inequlistid" multiple placeholder="请选择关联设备"  style="width:550px;">
            <el-option
              v-for="item in equOptions"
              :key="item.equid"
              :label="item.equname+ '-'+item.adderss"
              :value="item.equid">
            </el-option>
          </el-select>
        </el-form-item>

        <!-- 新增加字段 -->
        <el-form-item label="巡检要求" prop="inpointmark" style="line-height: 16px;">
          <textarea v-model="addfrom.inpointmark" class="fTextarea" placeholder="请输入巡检要求" maxlength="150" style="resize:none;"></textarea>
          <p class="YqText"><span>{{ addfrom.inpointmark.length }}</span>/150</p>
        </el-form-item>
        <div class="customBox">
          <p style="line-height: 20px;margin-bottom: 4px;color: rgba(0, 0, 0, 0.85);">自定义设备</p>
          <div class="customEqu">
            <div class="settingConfirm customAdd" @click="addCustomE">添加</div>
            <div class="flexAC customItem" v-for="(item, index) in addfrom.inpointcuomid" :key="index">
              <el-input class="ceAN" v-model="item.name"  placeholder="请输入设备名称"></el-input>
              <el-input class="ceAR" v-model="item.addr" placeholder="请输入设备地址"></el-input>
              <i class="el-icon-circle-close ceDel"  @click="dustomDel(index)"></i>
            </div>
          </div>
        </div>
      </el-form>
      <div class="flexCE">
        <div class="settingCancel" @click="addFromCancel">取消</div>
        <div class="settingConfirm" @click="AddFromConfirm">确定</div>
      </div>
    </el-dialog>

    <!-- 编辑 -->
    <el-dialog title="巡检点编辑" :visible.sync="editFromShow" width="600px" center class="gyDialog" :close-on-click-modal="false" :before-close="editFromCancel">
      <el-form :model="editfrom" :rules="editformRules" ref="editFrom">
        <el-form-item label="巡检点类型"  prop="inpointtype">
          <div class="typeBox">
            <el-radio-group v-model="editfrom.inpointtype">
              <el-radio label="1">二维码</el-radio>
              <el-radio label="2" disabled>NFC</el-radio>
            </el-radio-group>
          </div>
        </el-form-item>
        <el-form-item label="巡检点名称"  prop="inpointname">
          <el-input v-model="editfrom.inpointname" placeholder="请输入巡检点名称"></el-input>
        </el-form-item>
        <el-form-item label="定位" prop="inpointadress">
          <el-input v-model="editfrom.inpointadress" placeholder="请选择巡检点位置">
            <el-button slot="append" icon="el-icon-location" @click="showAddress"></el-button>
          </el-input>
        </el-form-item>
        <el-form-item label="关联设备" prop="equlistid">
          <el-select v-model="listIdArray" multiple placeholder="请选择关联设备" disabled style="width:550px;">
            <el-option
              v-for="item in equOptions"
              :key="item.equid"
              :label="item.equname+ '-'+item.adderss"
              :value="item.equid">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="巡检要求" prop="inpointmark" style="line-height: 16px;">
          <textarea  v-model="editfrom.inpointmark" class="fTextarea" placeholder="请输入巡检要求" maxlength="150" style="resize:none;"></textarea>
          <p class="YqText"><span>{{ calculatebyte(editfrom.inpointmark) }}</span>/150</p>
        </el-form-item>
        <div class="customBox" v-show="isCuom">
          <p style="line-height: 20px;margin-bottom: 4px;color: rgba(0, 0, 0, 0.85);">自定义设备</p>
          <div class="customEqu">
            <div class="customEqu">
                <div class="flexAC customItem" v-for="(item, index) in editfrom.pointcuom" :key="index">
                  <el-input class="ceAN" style="width: 35%;" v-model="item.name" disabled placeholder="请输入设备名称"></el-input>
                  <el-input class="ceAR" style="width: 65%;" v-model="item.addr" disabled placeholder="请输入设备地址"></el-input>
                </div>
            </div>
          </div>
        </div>
      </el-form>
      <div class="flexCE">
        <div class="settingCancel" @click="editFromCancel">取消</div>
        <div class="settingConfirm" @click="editFromConfirm">确定</div>
      </div>
    </el-dialog>

    <!-- 定位 -->
    <el-dialog class="mapDialog" :visible.sync="mapShow" width="800px"  :close-on-click-modal="false" >
      <Address :Address="address" @close="AddressClose" @save="AddressSave"></Address>
    </el-dialog>

    <!-- 二维码弹窗 -->
    <el-dialog
      class="download"
      :visible.sync="showImage"
      width="15%"
      @close="showImage = false">
      <div style="position: relative;text-align: center;width:100%;height: 100%">
        <div style="width: 100%;text-align: center">{{currentRow.inpointname}}</div>
        <el-image :src="currentRow.inpointqrcode" ></el-image>
        <div v-if="roleBtnArray[3].power" style="color: #196DF7; cursor: pointer;" @click="downLoad(currentRow.inpointqrcode)">下载</div>
      </div>
    </el-dialog>

    <!-- 详情弹框 -->
    <el-dialog  :visible.sync="detailShow" width="800px" center class="gyDialog" :close-on-click-modal="false">
      <div class="flexSC detailInfo">
        <div class="di_l">
          <div class="flexAC"><div class="udi_k"></div><p class="udi_t">巡检点基本信息</p></div>
          <div class="di_lInfo">
            <p class="di_lp">单位名称：<span>{{ information.companyname }}</span></p>
            <p class="di_lp">巡检点类型：
              <span class="sr1" v-if="information.inpointtype === '1'">二维码</span>
              <span class="sr1" v-if="information.inpointtype === '2'">NFC</span>
            </p>
            <p class="di_lp">巡检点名称：<span>{{ information.inpointname }}</span></p>
            <p class="di_lp">巡检点编号：<span>{{ information.inpointcode }}</span></p>
            <p class="di_lp">巡检计划创建时间：<span>{{ information.inpointtime }}</span></p>
          </div>
          <div class="di_sco">
            <p style="color: #333333;margin-bottom: 5px;line-height: 20px;">巡检要求：</p>
            <div class="di_text">
              {{ information.inpointmark }}
<!--              1、 认真监视并记录变频器人机界面上的各显示参数 ，发现异常应即时反映。-->
<!--              2、 认真监视并记录变频室的环境温度，环境温度应 在-5℃~40℃之间。移相变压器的温升不能超过130℃-->
<!--              3、 夏季温度较高时，应加强变频器安装场地应加强-->
            </div>
          </div>
        </div>
        <div class="di_r">
          <div class="di_rt" v-show="deListE">
            <div class="flexAC"><div class="udi_k"></div><p class="udi_t">关联设备</p></div>
            <div class="di_rSco">
              <p class="di_rp" v-for="item in information.equlistid" :key="item.index">
                <i class="iconfont iconround btIco"></i>{{ item.equname }}<span>（{{ item.adderss }}）</span>
              </p>
<!--              <p class="di_rp"><i class="iconfont iconround btIco"></i>视频联动<span>（陕西省西安市经济技术开发区文 景路中段220号一方中港国际B1办公位3号工 位）</span></p>-->
<!--              <p class="di_rp"><i class="iconfont iconround btIco"></i>电器火灾监测器<span>（二楼西南方向上方）</span></p>-->
            </div>
          </div>
          <div class="di_rb" style="margin-top: 10px" v-show="deListZ">
            <div class="flexAC"><div class="udi_k"></div><p class="udi_t">自定义设备</p></div>
            <div class="di_rSco">
              <p class="di_rp" v-for="item in information.pointcuom" :key="item.index"><i class="iconfont iconround btIco"></i>{{ item.name }}<span>（{{ item.addr }}）</span></p>
<!--              <p class="di_rp"><i class="iconfont iconround btIco"></i>电器火灾监测器<span>（二楼西南方向上方）</span></p>-->
<!--              <p class="di_rp"><i class="iconfont iconround btIco"></i>视频联动<span>（陕西省西安市经济技术开发区文 景路中段220号一方中港国际B1办公位3号工 位）</span></p>-->
<!--              <p class="di_rp"><i class="iconfont iconround btIco"></i>视频联动<span>（陕西省西安市经济技术开发区文 景路中段220号一方中港国际B1办公位3号工 位）</span></p>-->
            </div>
          </div>
        </div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import { getInspecpoint, addInspecpoint, updateInspecpoint, deleteInspecpoint, addNameSelect } from '@/http/api/onsite'
import { getEquipList } from '@/http/api/equip'
import Address from '@/components/setting/address.vue'
import { getequuuid } from '@/http/api/settingSystem'

export default {
  name: 'onsiteLabel',
  components: {
    Address
  },
  props: ['roleBtn'],
  data () {
    return {
      page: 1,
      size: 10,
      total: 0,
      lableList: [],
      inpointname: '',
      addFromShow: false,
      addfrom: {
        inpointtype: '1',
        inpointcode: '',
        inpointname: '',
        inpointadress: '',
        inlongitude: '',
        inlatitude: '',
        inequlistid: [],
        inpointmark: '',
        inpointcuomid: [
          { name: '', addr: '' }
        ]
      },
      formRules: {
        inpointtype: [
          { required: true, trigger: 'blur', message: '请选择巡检点类型!' }
        ],
        inpointname: [
          { required: true, trigger: 'blur', message: '请输入巡检点名称' }
          // { min: 1, max: 32, message: '长度在 1 到 50 个字符', trigger: 'change' }
        ],
        inpointadress: [
          { required: true, trigger: 'blur', message: '请选择巡检点位置!' }
        ],
        inequlistid: [
          { required: true, trigger: 'blur', message: '请选择关联设备!' }
        ],
        inpointmark: [
          { required: true, trigger: 'change', message: '请输入巡检要求' },
          { min: 1, max: 150, message: '长度在 1 到 150 个字符', trigger: 'change' }
        ]
      },
      equOptions: [],
      mapShow: false,
      address: '',
      editfrom: {},
      listIdArray: [],
      editFromShow: false,
      editformRules: {
        inpointtype: [
          { required: true, trigger: 'blur', message: '请选择巡检点类型!' }
        ],
        inpointname: [
          { required: true, trigger: 'change', message: '请输入巡检点类型!' },
          { min: 1, max: 32, message: '长度在 1 到 50 个字符', trigger: 'change' }
        ],
        inpointadress: [
          { required: true, trigger: 'blur', message: '请选择巡检点位置!' }
        ],
        inequlistid: [
          { required: true, trigger: 'blur', message: '请选择关联设备!' }
        ],
        equlistid: [
          { required: true, trigger: 'blur', message: '请选择关联设备!' }
        ],
        inpointmark: [
          { required: true, trigger: 'change', message: '请输入巡检要求' },
          { min: 1, max: 150, message: '长度在 1 到 150 个字符', trigger: 'change' }
        ]
      },
      showImage: false,
      currentRow: {},
      roleBtnArray: [],
      listidOption: [],
      detailShow: false,
      information: '',
      nameSelect: [],
      isCuom: false,
      deListE: false,
      deListZ: false,
      tipShow: false
    }
  },
  beforeMount () {
    if (this.roleBtn.length !== 0) {
      this.roleBtnArray = this.roleBtn
      // console.log(this.roleBtnArray)
    }
  },
  mounted () {
    this.companyid = JSON.parse(window.localStorage.getItem('loginInfo')).companyid
    this.getInit()
    this.init()
  },
  methods: {
    init () {
      const params = {
        page_index: this.page,
        page_size: this.size,
        companyid: this.companyid,
        inpointname: this.inpointname
      }
      getInspecpoint(params).then(res => {
        if (res.status === '1') {
          this.lableList = res.data.data
          // console.log(this.lableList)
          this.total = res.data.recordcount
        } else {
          this.lableList = []
          this.total = 0
        }
      })
    },
    PageChange (page) {
      this.page = page
      this.init()
    },
    handleSearch () {
      this.page = 1
      this.init()
    },
    handleReset () {
      this.page = 1
      this.inpointname = ''
      this.init()
    },
    handleDelete (row) {
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        deleteInspecpoint({ inpointid: row.inpointid }).then(res => {
          if (res.status === '1') {
            this.$message.success(res.message)
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {})
    },
    getInit () {
      /* 关联设备下拉 */
      getEquipList({ companyid: this.companyid }).then(res => {
        if (res.status === '1') {
          this.equOptions = res.data.data
          this.equOptions.forEach(item => {
            item.qrcode = item.inpointqrcode
          })
        } else {
          this.equOptions = []
        }
      })
      /* 巡检名称下拉 */
      addNameSelect ({ companyid: this.companyid }).then(res => {
        if (res.status === '1') {
          this.nameSelect = res.data
          // console.log(this.nameSelect)
        } else {
          this.nameSelect = []
        }
      })
    },
    // 二维码
    showCode (row) {
      this.showImage = true
      this.currentRow = row
    },
    downLoad (qrcode) {
      window.open(qrcode, '_blank')
    },
    showAddress () {
      this.mapShow = true
    },
    AddressClose () {
      this.mapShow = false
    },
    // 定位保存
    AddressSave (res) {
      this.mapShow = false
      this.addfrom.inpointadress = res.longitude + ',' + res.latitude
      this.addfrom.inlongitude = res.longitude
      this.addfrom.inlatitude = res.latitude

      this.editfrom.inpointadress = res.longitude + ',' + res.latitude
      this.editfrom.inlongitude = res.longitude
      this.editfrom.inlatitude = res.latitude
    },
    // 展示新增
    handleAdd () {
      this.addFromShow = true
      this.addfrom.inpointadress = ''
      this.addfrom.inlongitude = ''
      this.addfrom.inlatitude = ''
      getequuuid().then(res => {
        if (res.status === '1') {
          this.addfrom.inpointcode = res.data.equuid
        }
      })
    },
    // 新增提交
    AddFromConfirm () {
      this.$refs.addFrom.validate((valid) => {
        if (!valid) return
        const params = {
          companyid: this.companyid,
          inpointtype: this.addfrom.inpointtype,
          inpointcode: this.addfrom.inpointcode,
          inpointname: this.addfrom.inpointname,
          inlongitude: this.addfrom.inlongitude,
          inlatitude: this.addfrom.inlatitude,
          inequlistid: this.addfrom.inequlistid,
          inpointmark: this.addfrom.inpointmark,
          inpointcuomid: this.addfrom.inpointcuomid
        }
        addInspecpoint(params).then(res => {
          if (res.status === '1') {
            this.$message.success(res.message)
            this.addFromCancel()
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      })
    },
    addFromCancel () {
      this.$refs.addFrom.resetFields()
      this.addFromShow = false
    },
    // 展示编辑
    handleEdit (row) {
      this.editFromShow = true
      this.editfrom = { ...row }
      this.editfrom.inpointadress = row.inlongitude + ',' + row.inlatitude
      console.log(this.editfrom)
      console.log(Array.isArray(this.editfrom.pointcuom))
      this.listIdArray = []
      if (this.editfrom.equlistid.length > 0) {
        this.editfrom.equlistid.forEach(item => {
          this.listIdArray.push(item.equid)
        })
      }
      if (this.editfrom.pointcuom.length > 0) {
        this.isCuom = true
      } else {
        this.isCuom = false
      }
    },
    // 编辑提交
    editFromConfirm () {
      this.$refs.editFrom.validate((valid) => {
        if (!valid) return
        // const params = {
          // companyid: this.companyid,
          // inpointid: this.editfrom.inpointid,
          // inpointtype: this.editfrom.inpointtype,
          // // inpointcode: this.editfrom.inpointcode,
          // inpointname: this.editfrom.inpointname,
          // inpointadress: this.editfrom.inpointadress,
          // inlongitude: this.editfrom.inlongitude,
          // inlatitude: this.editfrom.inlatitude
          // // inequlistid: this.listIdArray,
        // }
        const params = {
          inpointid: this.editfrom.inpointid,
          companyid: this.companyid,
          inpointname: this.editfrom.inpointname,
          inlongitude: this.editfrom.inlongitude,
          inlatitude: this.editfrom.inlatitude,
          inpointmark: this.editfrom.inpointmark,
          inpointadress: this.editfrom.inpointadress
        }
        updateInspecpoint(params).then(res => {
          if (res.status === '1') {
            this.$message.success(res.message)
            this.editFromCancel()
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      })
    },
    /* 展示详情 */
    handleDetail (row) {
      this.detailShow = true
      this.information = { ...row }
      if (this.information.equlistid.length > 0) { this.deListE = true }
      else { this.deListE = false }
      if (this.information.pointcuom.length > 0) { this.deListZ = true }
      else { this.deListZ = false }
      console.log(this.information)
    },
    editFromCancel () {
      this.editFromShow = false
      this.$refs.editFrom.resetFields()
    },
    addCustomE () {
      let obj = { name: '', addr: '' }
      this.addfrom.inpointcuomid.push(obj)
    },
    dustomDel (eq) {
      this.addfrom.inpointcuomid.splice(eq, 1)
    },
    calculatebyte(sTargetStr) {
      var sTmpStr, sTmpChar;
      var nOriginLen = 0;
      var nStrLength = 0;
      sTmpStr = new String(sTargetStr)
      nOriginLen = sTmpStr.length
      for ( var i = 0; i < nOriginLen; i++ ) {
        sTmpChar = sTmpStr.charAt(i)
        nStrLength ++
      }
      return nStrLength
    },
    showHideTip () {
      this.tipShow = !this.tipShow
    }
  }
}
</script>

<style scoped>
.lable_cont{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
}
.lable_box{
  width: 100%;
  height: 100%;
  position: relative;
  background: #fff;
  padding: 30px;
  box-sizing: border-box;
}
.ut_top,.lable_table {
  margin-top: 20px;
}
.typeBox {
  width: 100%;
  margin-top: 24px;
}
.fTextarea::-webkit-input-placeholder{
  color: #DCDFE6;
}
.fTextarea{
  width: 100%;
  height: 100px;
  border: 1px solid #DCDFE6;
  border-radius: 4px;
  margin-top: 4px;
  padding: 10px;
  box-sizing: border-box;
  color: #606266;
  font-size: 14px;
}
.fTextarea:focus{
  border-color: #409EFF;
  /*border-color: #F56C6C;*!*/
}
.YqText{
  position: absolute;
  top: -8px;
  right: 10px;
}
.YqText span{
  color: #196DF7;
}
.customBox{
  margin-bottom: 22px;
}
.customEqu{
  width: 100%;
  position: relative;
}
.customAdd{
  width: 12%;
  position: absolute;
  right: 0;
}
.customItem{
  margin-bottom: 10px;
}
.customItem:last-child{
  margin-bottom: 0px;
}
.ceAN{
  width: 32%;
}
.ceAR{
  width: 54%;
  margin-left: 5px;
}
.ceDel{
  margin-left: 10px;
  font-size: 20px;
  color:red;
  cursor: pointer;
}
.detailInfo{
  width: 100%;
  position: relative;
}
.di_l{
  width: 55%;
  padding-right: 20px;
  box-sizing: border-box;
  border-right: 2px solid #EBEDF5;
}
.di_r{
  width: 45%;
  box-sizing: border-box;
  padding-left: 20px;
}
.di_lInfo{
  width: 100%;
  margin-top: 10px;
}
.di_lp{
  width: 100%;
  background: #F6F9FF;
  color: #333333;
  margin-bottom: 8px;
  border-radius: 4px;
  padding: 9px 12px;
  box-sizing: border-box;
}
.di_lp{
  color: #333333;
}
.di_lp span{
  color: #8291A1;
}
.di_lp .sr1{ color: #196DF7; }
.di_sco{
  width: 100%;
  height: 180px;
  box-sizing: border-box;
  background: #F6F9FF;
  padding: 10px;
}
.di_text{
  width: 100%;
  height: 125px;
  color: #8291A1;
  padding-right: 5px;
  overflow: auto;
  scrollbar-width: none;/* //火狐兼容 */
}
.di_rSco{
  width: 100%;
  height: 160px;
  color: #8291A1;
  overflow: auto;
  background: #F6F9FF;
  scrollbar-width: none;/* //火狐兼容 */
  padding: 10px 15px 10px 10px;
  box-sizing: border-box;
  margin-top: 7px;
}
.di_text::-webkit-scrollbar, .di_rSco::-webkit-scrollbar{/*滚动条整体样式*/
  width: 2px;     /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.di_text::-webkit-scrollbar-thumb, .di_rSco::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
  background: #CADDFE;
}
.di_text::-webkit-scrollbar-track, .di_rSco::-webkit-scrollbar-track { /*滚动条里面轨道*/
  border-radius: 10px;
  background: #EDEDED;
}
.di_rp{
  font-size: 14px;
  line-height: 20px;
  color: #333333;
}
.di_rp i{
  font-size: 15px;
  font-weight: 800;
  margin-right: 6px;
  color: #196DF7;
}
.di_rp span{
  color: #8291A1;
}
.tableHI{
  color: #196DF7;
  cursor: pointer;
  font-size: 14px;
}
.tableTip{
  width: 220px;
  height: 30px;
  position: absolute;
  top: -15px;
  z-index: 10;
  left: 34.5%;
}
.tipT{
  padding: 8px 10px;
  box-sizing: border-box;
  font-size: 13px;
  background: #196DF7;
  color: #fff;
  border-radius: 4px;
}
.triangle{
  width: 0px;
  height: 0px;
  position: absolute;
  line-height: 0px;
  right: 10px;
  top: 23px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 8px solid rgb(25 109 247);
}
</style>
